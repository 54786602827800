<template>
  <div class=" p-2" style="min-height: 200px">
    <div class="table-responsive border-b border-l border-r">
      <table class="table _table_width">
        <thead>
        <tr>
          <th style="width: 6%">Date</th>
          <th>Txn Type</th>
          <th>Voucher No</th>
          <th style="width: 20%">Account</th>
          <th>Debit</th>
          <th>Credit</th>
          <th v-if="!modal">Balance</th>

          <th style="width: 10%">Line Description</th>
          <th>Party name</th>
          <th>Payment Ref.</th>
          <th>Payment Ref. Date</th>
          <th>Business Unit</th>
          <th>Cost Centre</th>
          <th>Project name</th>
          <th>Project Account Head</th>
        </tr>
        </thead>

        <tbody>
        <tr v-if="!modal && (currentPage === 1 || ((currentPage == undefined) && (currentPage === lastPage)))">
          <td></td>
          <td></td>
          <td></td>
          <td> Opening Balance </td>
          <td class="text-end">  </td>
          <td class="text-end">  </td>
          <td class="text-end"> {{ openingBalance ? commaFormat(openingBalance) : 0 }} </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-for="(item, i) in ledgers" :key="i">
          <td>
            <span class="fw-bold">{{ item.date }}</span>
          </td>
          <td> {{ item.txn_type }} </td>
          <td> {{ item.voucher_no }} </td>
          <td>
              <span>
                <span v-if="(item.debit == null || item.debit === 0)">
                    {{ item.debit_account_head || '' }}
                </span>
                 <span v-if="(item.credit == null || item.credit === 0)">
                    {{ item.credit_account_head || '' }}
                </span>
              </span>
            |
            <button class="btn btn-outline-primary btn-sm"
                    title="Print voucher"
                    @click="goToPrint(item.ledger_master_id, item.voucher_type)"
                    style="cursor: pointer;margin-left: 5px;margin-right: 5px;"
            >
              <i class="fas fa-print"></i>
            </button>

            <span v-if="item.attachments && item.attachments.length > 0" class="cursor-pointer">
                    <button
                      title="View attachment"
                      class="btn btn-outline-secondary btn-sm"
                      style="cursor: pointer"
                      @click="viewAttachments(item.attachments)"
                    >
                      <i class="fas fa-paperclip"></i>

                    </button>
              </span>
          </td>
          <td class="text-end"> {{ commaFormat(item.debit) }} </td>
          <td class="text-end"> {{ commaFormat(item.credit) }} </td>
          <td class="text-end" v-if="!modal"> {{ commaFormat(item.balance) }} </td>
          <td> {{ item.line_description }} </td>
          <td>{{ item.party ? item.party.full_name : '' }}</td>
          <td>{{ item.master ? checkNullAndFormatValue(item.master.payment_ref) : '' }}</td>
          <td>{{ item.master ? removeAbnormalDate(item.master.payment_ref_date) : '' }}</td>
          <td>{{ item.business ? item.business.name : '' }}</td>
          <td>{{ item.cost_centre ? item.cost_centre.name : '' }}</td>
          <td>{{ item.project ? item.project.name : '' }}</td>
          <td>{{ item.project_accounts ? item.project_accounts.name : '' }}</td>
        </tr>
        <tr v-if="currentPage === lastPage">
          <td></td>
          <td></td>
          <td></td>
          <td> <slot v-if="!modal">Closing Balance</slot> <slot v-else>Total</slot> </td>
          <td class="text-end"> {{ totalDebitBalance ? commaFormat(totalDebitBalance) : 0 }}</td>
          <td class="text-end"> {{ totalCreditBalance ? commaFormat(totalCreditBalance) : 0 }}  </td>
          <td class="text-end" v-if="!modal"> {{ closingBalance ? commaFormat(closingBalance) : 0 }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
      </table>
      <p v-if="!ledgers.length" class="mt-5 text-center">No data</p>

        <ViewAttachment
            ref="viewAttachmentRef"
            :attachments="modalAttachments"
        />

    </div>
  </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
import { useVoucherPrint } from '@/services/utils/voucherPrint';
import { checkNullAndFormatValue, removeAbnormalDate } from '@/services/utils/global';
import ViewAttachment from '@/views/page/company/ngo/voucher/payment/ViewAttachmentModal.vue'
import { ref } from 'vue';
export default {
  name: 'LedgerTableOld',
  props: {
    openingBalance: Number,
    closingBalance: Number,
    totalDebitBalance: Number,
    totalCreditBalance: Number,
    currentPage: Number,
    lastPage: Number,
    ledgers: {
      type: Array
    },
    modal: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ViewAttachment
  },
  computed: {
  },
  methods: {
    seeDetails(id, txnType) {
      let name = "";
      let allParams = {
        companyId: this.$route.params.companyId,
        moduleId: this.$route.params.moduleId,
        menuId: this.$route.params.menuId,
        pageId: this.$route.params.pageId,
      };

      switch (txnType){
        case "general_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "bill_journal" :
          name = 'purchase-bill-edit';
          allParams.purchaseId = id;
          break;
        case "invoice_journal" :
          name = 'sales-invoice-edit';
          allParams.salesId = id;
          break;
        case "adjustment_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "correction_journal" :
          name = 'EditJournal';
          allParams.journalId = id;
          break;
        case "general_receipt" :
          name = 'EditReceipt';
          allParams.receiptId = id;
          break;
        case "invoice_receipt" :
          name = 'money-receipt-edit';
          allParams.receiptId = id;
          break;
        case "general_payment" :
          name = 'EditPayment';
          allParams.paymentId = id;
          break;
        case "bill_payment" :
          name = 'pay-bill-edit';
          allParams.paymentId = id;
          break;
        case "general_contra" :
          name = 'EditContra';
          allParams.contraId = id;
          break;
      }

      this.$router.push({name: name,
        params: allParams,
        query: {
          start: this.$route.query.start,
          end:  this.$route.query.end
        }
      })
    }
  },
  setup() {
    const { commaFormat } = figureFormatter()
    const { printVoucher, getType } = useVoucherPrint();
    const goToPrint = (id, voucherType) => {
      printVoucher(id, voucherType)
    }

    const modalAttachments = ref([]);
    const viewAttachmentRef = ref();

    const viewAttachments = (attachments) => {
      modalAttachments.value = [];
      modalAttachments.value = attachments;
      viewAttachmentRef?.value.toggleModal();
    }
    return {
      commaFormat,
      modalAttachments,
      viewAttachmentRef,
      goToPrint,
      viewAttachments,
      checkNullAndFormatValue,
      removeAbnormalDate
    }
  },
}
</script>

<style scoped>

._table_width{
  width: 200%;
}
.table> :not(caption)>*>* {
  padding: 0.72rem 0.75rem !important;
}

.table thead th {
  vertical-align: middle !important;
}
</style>